.blogentry-v1 {
	padding-top: var(--page-margin-top);

	.img-thumbnail {
		height: 100% !important;
	}

	.date {
		padding-top: 10px;
		font-size: var(--font-size-md);
	}

	.content-block {
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	}

	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.tag-box {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;

		@media (max-width: 991px) {
			margin: 15px 0 0 0 !important;
			justify-content: start;
		}

		li {
			a {
				margin: 0 10px 10px 0;
			}
		}
	}

	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}

}