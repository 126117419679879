.searchbar-v3 {
	position: absolute;
	top: 77vh;
	width: 100%;

	.toggle-labels {
		position: relative;

		@media (max-width: 992px) {
			margin-top: 25px;
		}

		>span {
			color: var(--color-black);
			font-size: 14px;
			position: absolute;
			top: -25px;

			@media (max-width: 992px) {
				top: -30px;
			}
		}

		.switch-toggle {
			border-radius: 20px;
			overflow: hidden;
		}

		input+label {
			margin-bottom: 0;
			font-size: 16px;
			padding: 7px 10px;
			border-right: 1px solid #fff;

			&:last-child {
				border-right: none;
			}
		}
	}

	@media (max-width: 1199px) {
		padding: 0px 15px;
	}

	@media (max-width: 992px) {
		top: 55vh;
	}

	@media (max-height: 600px) {
		top: 350px;

	}

	>.container {
		padding: 0;
		max-width: 1000px;

		>.row {
			@media (max-width: 1199px) {
				margin: 0 !important;
			}
		}
	}



	.tab {
		color: #fff;
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 6px 20px;
		opacity: 1;
		cursor: pointer;
		display: inline-block;

		@media (max-width: 767px) {
			padding: 6px 10px;
			font-size: 15px;
		}

		@media (max-width: 374px) {
			padding: 6px 7px;
			font-size: 13px;
		}


		&.active {
			background-color: var(--color-primary);
			color: #fff;
		}

	}

	#search-obj-form {
		display: none;
	}

	#contact-view {
		display: none;
		padding: 30px 30px 15px 30px;
		flex-wrap: wrap;
		align-items: unset;
		justify-content: unset;
		flex-direction: column;

		@media (max-width: 767px) {
			padding: 15px;
		}

		h3 {
			width: 100%;
		}

		ul {
			margin: 0 !important;
			display: flex;
			align-items: center;
			margin: 0px !important;
			flex-wrap: wrap;

			li {
				margin: 0 0 15px;
				position: relative;
				padding: 0 0 0 25px;
				width: 33.33%;
				margin: 0 !important;

				@media (max-width: 767px) {
					width: 100%;
					font-size: 16px;
					margin: 5px 0 !important;
				}

				@media all and (min-width: 768px) and (max-width: 991px) {
					width: 100%;
					margin: 5px 0 !important;
				}

				i {
					position: absolute;
					left: 0;
					top: 5px;
				}
			}
		}
	}

	.search-form-area {

		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #fff;
		background-color: rgb(255 255 255 / 85%);
		flex-flow: wrap;
		border: 10px solid #c3c3c3;
		border: 0 solid var(--color-grey-normal);
		border-radius: 0 8px 8px 8px;

		.searchbox-obj {
			padding-top: 0px;
			width: 100%;
		}

		&.search-form-area-contact {
			flex-wrap: wrap;
			flex-direction: column;

			ul {
				padding: 15px 30px;
			}
		}

		.form-obj-flex {
			width: 90%;
			padding: 25px 15px;


			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid rgb(0 0 0/40%);
				border-right: none;
				padding: 15px 12px;
			}

			.fa {
				position: absolute;
				left: 10px;
				top: 16px;
			}

			.form-control {
				background-color: transparent;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: 1px solid rgb(0 0 0 / 40%);
				min-height: 40px !important;
				font-size: 14px;
				line-height: 20px;
				padding: 8px 30px;
				min-height: 50px !important;
				padding-left: 40px;
			}

			.form-flex-area {
				position: relative;
			}

		}

		.form-flex {
			width: 30%;
			padding: 25px 15px;
			border-right: 1px solid rgb(0 0 0 / 40%);


			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid rgb(0 0 0 / 40%);
				border-right: none;
				padding: 15px 12px;
			}


			.fa {
				position: absolute;
				left: 10px;
				top: 16px;
			}

			#datepicker-trigger-flex {

				height: auto !important;
				padding-right: 30px;
			}

			.form-control {
				background-color: transparent;
				border: none;
				text-align: left;
				z-index: 1;
				position: relative;
				cursor: pointer;
				border: 1px solid rgb(0 0 0 / 40%);
				padding: 8px 10px 8px 30px;
				min-height: 50px !important;
				font-size: 13px;
				line-height: 20px;
				padding-right: 10px;
			}

			.form-flex-area {
				position: relative;
			}
		}

		.form-btn {
			width: 10%;
			display: flex;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;
			padding-left: 10px;

			.searchbox {
				width: 100%;
			}

			@media (max-width:992px) {
				width: 100%;
				padding: 15px 10px;
			}

			.btn {
				width: 101%;
				height: 75px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}