.navbar-v1.affix,
.navbar-v1 {

	position: fixed;
	width: 100%;
	top: 0;
	border-width: 0 0 1px;
	padding: 0;
	z-index: 50;
	box-shadow: 0 3px 3px -3px grey;
	background-color: var(--nav-bg-color);


	@media (max-width: 1199px) {
		padding: 10px 0;
	}

	@media (max-width:1200px) {
		position: fixed;
	}

	.input-search-unit {
		padding: 5px 15px;
		max-width: 280px;

		@media (max-width:1200px) {
			max-width: 100%;

		}

		@media (max-width:1200px) {
			margin-bottom: 5px;
		}

		.form-control {
			padding: 5px 10px;
			width: 200px;

		}

		.form-control::placeholder {
			font-size: 0.875rem;
			color: #aaa;
			font-style: italic;
		}

		.btn {
			border-radius: 0 5px 5px 0;
			padding: 0px 15px;
			background: var(--color-secondary);
			border-color: var(--color-secondary);
		}
	}

	.top-nav {
		padding: 3px 0 8px;
		text-align: right;
		font-size: var(--top-nav-font-size);
		font-family: var(--bodyfont);
		background-color: var(--top-nav-bg-color);

		ul {
			padding: 0;
			margin: 0;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: flex-end;

			li {
				display: inline-block;
				position: relative;
				font-size: var(--top-nav-font-size);
				color: var(--top-nav-font-color);

				@media (max-width:1200px) {
					display: block;
				}

				a {
					color: var(--top-nav-font-color);
					font-size: var(--top-nav-font-size);
					text-decoration: none;
					padding: 5px 15px;

					@media (max-width:550px) {
						padding: 5px 4px;
					}

					&:hover {
						color: var(--top-nav-font-color-hover)
					}

					i.fa {
						font-size: var(--top-nav-icon-size);
						margin-right: 5px;
					}
				}
			}
		}



	}

	.header-main {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;

		.header-logo {
			position: static;
			top: 5px;
			z-index: 20;
			width: 13%;

			@media (max-width: 767px) {
				width: 20%;
			}

			.logo {
				width: 100px;
				height: auto;

				@media (max-width:1199px) {
					width: 70px;
					max-width: 100%;
				}

				@media (max-width:767px) {
					width: 65px;
					max-width: 100%;
				}
			}

		}

		.search-form-area {
			width: 58%;

			@media (max-width: 1200px) {
				display: none;
			}

			@media all and (min-width: 1201px) and (max-width: 1500px) {
				width: 54%;
			}

			.sticky-searchbar {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				.form-flex {
					width: 44%;
					padding: 0 15px;

					.form-flex-area {
						position: relative;
					}

					.form-control {
						background: transparent;
						color: #fff;
						border-radius: 3px;
						min-height: 50px;
						height: auto;
						padding-left: 40px;
						text-align: left;
						cursor: pointer;
						font-size: 14px;
					}

					.fa {
						position: absolute;
						top: 17px;
						color: #fff;
						left: 15px;
						z-index: -1;

					}
				}

				.form-btn {
					width: 12%;
					padding: 0 15px;

					a {
						background: var(--color-secondary);
						min-height: 50px;
						height: auto;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						border: 1px solid var(--color-secondary);

						&:hover {
							background: #fff;
							color: var(--color-secondary);
						}
					}
				}

			}
		}

		.main-menu {
			float: right;
			position: fixed;
			left: 0;
			top: 100%;
			background-color: var(--main-nav-mob-menu-bg-color);
			width: 100%;
			max-width: 25%;
			left: auto;
			right: 0;
			top: 0;
			border: none;
			height: 100vh;
			padding: 30px 0 0 0;
			display: block !important;
			transform: translateX(100%);
			transition: all 0.35s;

			@media (max-width: 991px) {
				max-width: 300px;
			}

			@media all and (min-width: 992px) and (max-width: 1350px) {
				max-width: 400px;
			}

			&.menuopen {
				transform: translateX(0%);
			}

			.open-menu-phone {
				padding: 0 20px 0 20px;

				a {
					color: #fff;
					display: flex;
					align-items: center;
					border-bottom: 1px solid rgb(255 255 255 / 10%);
					padding: 0 0 20px 0;
					margin: 0 0 10px 0;

					i {
						margin: 0 10px 0 0;
					}
				}
			}

			>ul {
				padding: 0;
				margin: 0;

				>li {
					float: left;
					/* display: inline-block;

					@media (max-width:1200px) { */
					display: block;
					width: 100%;
					/* } */

					&:last-child {
						margin-right: 0;
					}

					>a {
						color: var(--main-nav-font-color);
						text-transform: var(--main-nav-text-transform);
						font-size: var(--main-nav-font-size);
						padding: 12px 20px;
						line-height: 1;
						display: block;
						font-weight: 400;

						@media (max-width:1199px) {
							font-size: var(--main-nav-font-size);
							color: var(--color-white);
						}

						@media (max-width:1100px) {
							padding: 10px 15px;
							text-transform: capitalize;
							font-size: var(--main-nav-font-size);
							color: var(--main-nav-mob-font-color-hover);
						}


						&:hover {
							color: var(--main-nav-font-color-hover);
							background-color: var(--main-nav-bg-hover);
							text-decoration: none;
						}



					}

					&.dropdown {

						cursor: pointer;

						.dropdown-backdrop {
							display: none;
						}


						&.show {
							background-color: var(--main-nav-bg-hover);

							a {
								color: var(--main-nav-font-color-hover);
							}
						}
					}




				}
			}

			.btn {
				@media (max-width:340px) {
					font-size: 12px;
					padding: 8px 8px;
				}
			}
		}

		.social-icons {
			margin-right: 30px;
			margin-bottom: 0;

			a {
				color: #fff;

				&:hover {
					color: #fff;
				}
			}
		}
	}

	.dropdown-menu {
		border-radius: 0;
		border: none;
		box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
		min-width: 300px;
		margin-top: -1px;

	}

	.sub-nav {

		ul {
			list-style-type: none;
			padding-left: 20px;

			li {

				a {
					padding: 10px 0;
					color: var(--main-sub-font-color) !important;
					display: block;
					font-size: 17px;

					@media (max-width:992px) {
						padding: 10px;
						font-weight: 400;
						text-transform: initial;
					}

					&:hover {
						color: var(--main-sub-font-color-hover) !important;
						text-decoration: none;

					}
				}

			}
		}


	}

	.icon {
		display: none;
		position: static;
		right: 10px;
		text-decoration: none;
		color: var(--color-secondary);
		font-size: 30px;

	}

	.navactive {
		@media (min-width:1201px) {
			color: var(--main-nav-font-color-hover) !important;
			background-color: var(--main-nav-bg-hover) !important;
		}
	}


	/* @media screen and (max-width: 1200px) { */

	.top-nav {
		padding: 10px 0 8px;
		text-align: right;


		.input-search-unit {
			display: none;
		}
	}

	.header-logo {
		padding-left: 0px !important;
	}

	.nav-row {
		min-height: 110px !important;
		width: 30%;
		position: static;

		@media (max-width: 767px) {
			width: 80%;
		}

		@media all and (min-width: 768px) and (max-width: 1200px) {
			width: 60%;
			min-height: 20px !important;
		}

		@media all and (min-width: 1201px) and (max-width: 1500px) {
			width: 35%;
		}

		.nav-row-inner {
			position: static;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.header-phone {
				padding: 0 30px 0 0;
				margin: 0 0 0px 0;
				display: flex;

				@media (max-width: 767px) {
					padding: 0 20px 0 0;
					font-size: 16px;
				}

				@media (max-width: 375px) {
					padding: 0 20px 0 0;
					font-size: 13px;
				}

				li.fav-count {
					position: relative;
					margin: 0 30px 0 0;
				}

				li.header-search {
					display: none;

					@media (max-width: 1200px) {
						display: block;
						margin-right: 25px;
					}
				}

				li {
					transition: all .35s;

					&.hidephone {
						opacity: 0;
						visibility: hidden;
					}

					a {
						color: #fff;

						@media (max-width: 767px) {
							font-size: 0;
						}

						i {
							margin: 0 5px 0 0;

							@media (max-width: 767px) {
								font-size: 16px;
							}
						}
					}
				}
			}

			.fav-count {
				.sub-count {
					-moz-border-radius: 50%;
					-webkit-border-radius: 50%;
					border-radius: 50%;
					display: block;
					position: absolute;
					top: -10px;
					left: 12px;
					width: 18px;
					height: 18px;
					background: var(--color-secondary);
					color: var(--color-white);
					text-align: center;
					font-size: 12px;
					line-height: 18px;
				}
			}
		}


	}

	.icon {
		display: block;
		text-align: right;
		float: right;
		position: static;
		top: 4px;
		width: 25px;

		.menubar {
			i {
				width: 25px;
				height: 3px;
				background: #fff;
				margin-bottom: 5px;
				display: block;
				border-radius: 10px;
			}

		}

		.menuclose {
			position: absolute;
			z-index: 99;
			color: #fff;
			top: 15px;
			right: 25px;
			left: unset;

			@media (max-width: 767px) {
				right: 20px;
				z-index: 99;
				top: 25px;
				position: fixed;
				font-size: 24px;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				right: 15px;
				z-index: 99;
				top: -3px;
				position: relative;
				font-size: 24px;
			}

			@media all and (min-width: 992px) and (max-width: 1199px) {
				z-index: 99;
				top: 20px;
				right: 20px;
			}

			@media all and (min-width: 1200px) and (max-width: 1350px) {
				right: 25px;
			}
		}
	}

	.dropdown-menu {
		position: relative !important;
		transform: none !important;
		width: 100%;
		border-top: none;
	}

	.main-menu {
		background-color: var(--nav-bg-color);
		padding: 5px 0;
		z-index: 50;
		display: none;
		width: 100%;
		border-bottom: 1px solid #61847a;
	}

	.search-unit-ul {
		margin: 15px 0px 0px;

		.input-search-unit {
			.input-group {
				.form-control {
					@media (max-width: 574px) {
						height: 40px !important;
					}
				}
			}
		}
	}

	/* } */

	@media screen and (max-width: 768px) {

		.top-nav {
			text-align: center;

			.input-search-unit {
				display: none;
			}
		}

		.header-main {

			.header-logo {
				position: static;
				text-align: left;

			}

			.nav-row {
				min-height: 20px !important;
			}

			.icon {
				position: static;
				bottom: 30px;
				right: 10px;
			}
		}

	}

	@media screen and (min-width: 1201px) {
		.main-menu {
			/* display: block !important;*/
		}
	}

	&.affix-top {
		border-bottom: var(--nav-border-bottom-affix-top);
		background-color: var(--nav-bg-color-affix-top);
		z-index: 4;

		.top-nav {
			background-color: var(--top-nav-bg-color-affix-top);

			.input-search-unit {
				@media (max-width:1200px) {
					display: none;
				}
			}

			ul {

				li {
					color: var(--top-nav-font-color-affix-top);

					@media (max-width:1100px) {
						display: block;
					}

					a {
						color: var(--top-nav-font-color-affix-top);
						font-size: var(--top-nav-font-size);
						text-decoration: none;
						padding: 5px 15px;

						@media (max-width:550px) {
							padding: 5px 4px;
						}

						&:hover {
							color: var(--top-nav-font-color-affix-top-hover);
						}

					}
				}
			}
		}



		.header-main {

			.header-logo {

				.logo {}

			}

			.main-menu {

				>ul {
					padding: 0;
					margin: 0;

					>li {
						>a {
							color: var(--main-nav-font-color-affix-top);

							@media (max-width: 1200px) {
								color: var(--color-white);
							}


							&:hover {
								color: var(--main-nav-font-color-affix-top-hover);
								background-color: var(--main-nav-bg-affix-top-hover);
								text-decoration: none;
							}



						}

						&.dropdown {

							cursor: pointer;

							.dropdown-backdrop {
								display: none;
							}


							&.show {
								background-color: var(--main-nav-bg-hover);

								a {
									color: var(--main-nav-font-color-hover);
								}
							}
						}




					}
				}

				.btn {
					@media (max-width:340px) {
						font-size: 12px;
						padding: 8px 8px;
					}
				}
			}
		}
	}

}

.mobil-menu {
	position: fixed;
	cursor: pointer;
	display: none;
	bottom: 20px;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 200;
	line-height: 40px;
	text-align: center;
	font-size: 24px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	-o-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	display: none !important;

	/* @media (min-width:1101px) {
		display: none !important;
	} */
}

.open-by-mob {
	#mob-nav-icon {
		display: none;
	}

}