.ausstattung-page-layout-v1 {
	margin-top: 110px;

	.inner-banner {
		background-image: url(RESOURCE/img/ausstattung-banner-1.jpg);
	}

	.service-box {
		@media (max-width: 767px) {
			width: 50%;
		}

		@media (max-width: 375px) {
			width: 100%;
		}
	}

	.last-section {
		margin-top: 40px;

		@media (max-width: 767px) {
			flex-direction: column-reverse;
		}
	}
}