.Lage-page-layout-v1 {
	.inner-banner {
		background-image: url(RESOURCE/img/lage-banner.jpg);
	}
}

.lage-plan {

	overflow: hidden;



	.lage-plane-inner {
		position: relative;

		.desktop-img {
			display: none;

			@media (min-width: 768px) {
				display: block;
			}
		}

		.mob-img {
			display: none;

			@media (max-width: 767px) {
				display: block;
			}
		}

		.popper {
			background: #c41e1b;
			width: 57px;
			height: 57px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
			border-radius: 100px;
			position: absolute;
			text-align: center;

			@media (max-width: 767px) {
				width: 11%;
				height: 11%;
			}

			@media all and (min-width: 768px) and (max-width: 991px) {
				width: 27px;
				height: 27px;
			}


			>img {
				max-width: 100%;
			}

			&.popper-1 {
				left: 24%;
				top: 43%;

				@media (max-width: 767px) {
					left: 64%;
					top: 45%;
				}
			}

			&.popper-2 {
				left: 41%;
				top: 25%;

				@media (max-width: 767px) {
					display: none;
				}
			}

			&.popper-3 {
				left: 54%;
				top: 40%;

				@media (max-width: 767px) {
					left: 8%;
					top: 23%;
					right: unset;
				}
			}

			&.popper-4 {
				left: 71%;
				top: 52%;

				@media (max-width: 767px) {
					left: 41%;
					top: 17%;
				}
			}
		}
	}

	.main-img {
		img {
			max-width: 100%;
		}
	}
}