.mit-hund-page-layout-v1 {
	margin-top: 110px;

	.inner-banner {
		background-image: url(RESOURCE/img/mit-hund-banner-1.jpg);
		background-position: bottom;
		height: 650px;

		@media (max-width: 1650px) {
			height: 550px;
		}

		@media (max-width: 1550px) {
			height: 500px;
		}

		@media (max-width: 1399px) {
			height: 450px;
		}

		@media (max-width: 1199px) {
			height: 400px;
		}

		@media (max-width: 1024px) {
			height: 350px;
		}

		@media (max-width: 991px) {
			height: 330px;
		}

		@media (max-width: 767px) {
			height: 250px;
		}

		@media (max-width: 615px) {
			height: 230px;
		}

		@media (max-width: 480px) {
			height: 180px;
		}
	}

	.single-unit {
		margin-bottom: 30px;
	}
}