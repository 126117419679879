.anlagen-page-layout-v1 {
	margin-top: 110px;

	.inner-banner {
		background-image: url(RESOURCE/img/anlagen-banner.jpg);
	}

	.anlagen-box {
		margin-bottom: 30px;

		&:hover {
			img {
				-webkit-filter: brightness(.7);
				filter: brightness(.7);
			}
		}

		img {
			width: 100%;
			-webkit-transition: .5s;
			transition: .5s;
			height: 400px;
			object-fit: cover;

			@media (max-width: 1200px) {
				height: 300px;
			}

			@media (max-width: 991px) {
				height: 250px;
			}

			@media (max-width: 767px) {
				height: 250px;
			}

			@media (max-width: 480px) {
				height: 250px;
			}
		}

		.box-content {
			border: 1px solid var(--color-grey-normal);
			padding: 15px;

			h4 {
				margin: 0 0 20px 0;
				font-family: var(--h5-font-family);
				font-weight: var(--h5-font-weight);
				font-size: var(--h5-font-size);
				display: flex;
				align-items: center;
				position: relative;
				justify-content: space-between;

				.btn-primary {
					margin: 0 0 0 10px;
				}
			}
		}

	}
}