.about-us-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/about-us-banner.jpg);

		&.social {
			background-image: url(RESOURCE/img/banner-social-new.jpg);
			background-positon: center;
		}
	}

	.team-members {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(min(var(--about-us-grid-width), 100%), 1fr));
		grid-gap: 1em;
	}

	.member-info {
		text-align: center;
		background-color: var(--color-white);

		.name {
			font-weight: 600;
		}

		.resort {
			font-size: var(--font-size-md);
			color: var(--color-grey-dark);
			margin-bottom: 10px;
		}

		.phone {
			font-size: var(--font-size-md);
		}

		.email {
			font-size: var(--font-size-md);
		}

	}

	.uber-uns-1,
	.uber-uns-2,
	.uber-uns-3 {
		margin-bottom: 30px;

		@media (max-width: 767px) {
			margin-bottom: 0;
		}

	}

	.uber-uns-3 {
		@media (max-width: 991px) {
			flex-direction: column-reverse;
			margin-top: 20px !important;
		}

		@media (max-width: 767px) {
			flex-direction: column-reverse;
			margin-top: 20px !important;
			text-align: left !important;
		}
	}

	.row {
		img {
			max-width: 100%;
		}
	}

}