@media print {

	*,
	*:before,
	enter code here *:after {
		color: #000 !important;
		text-shadow: none !important;
		background: transparent !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		margin: 0;
		padding: 0
	}

	a[href]:after {
		content: none !important;
	}

	a,
	a:visited {
		text-decoration: underline
	}

	a[href]:after {
		content: " ("attr(href) ")"
	}

	abbr[title]:after {
		content: " ("attr(title) ")"
	}

	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: ""
	}

	.header-print {
		left: 0;
		position: fixed;
		top: ;
		display: table-header-group;
	}

	.unit-view .showLess .unit-widgt-bl {
		display: block !important;
	}

	.show-more,
	.owl-nav {
		display: none;
	}

	.v-img-gallery {
		display: block !important;

		a {
			float: left;

			.img-thumbnail {
				width: 20% !important;
				margin: 10px;


			}
		}
	}


	p {
		font-size: 13px
	}

	h3 {
		font-size: 16px
	}


	.hidden-print {
		display: none;
		visibility: hidden
	}

	.v-img-gallery {
		margin: 20px 0;

		a {
			&:nth-child(n + 6) {
				display: none;
			}
		}


	}



	/*ul {
		li:nth-of-type(1n+4) {
			display: none;
		}
	}*/

	@page {
		margin: 2mm;
	}

	.print-gap {
		margin-top: 80px;
	}

	.unit-view {
		padding-top: 10px
	}

	.page-brake {
		page-break-after: always
	}

	.text-blue {
		color: #0060a1 !important
	}

	.txt-orange {
		color: #fa590c !important
	}

	.text-grey {
		color: #797979 !important
	}
}

.unit-section {
	margin-bottom: var(--unit-section-mb);
}

.unit-map {
	width: 100%;
	height: var(--unit-map-height);
	position: relative;
}


@media (max-width: 992px) {
	.unit-view {
		.owl-carousel-unit {
			.img-thumbnail {
				height: 500px;
				object-fit: cover;
			}
		}
	}
}

@media (max-width: 767px) {
	.unit-view {
		.owl-carousel-unit {
			.img-thumbnail {
				height: 350px;
				object-fit: cover;
			}
		}
	}
}

.similar-units {

	position: relative;
	height: 440px;
	transition: all 0.5s ease-out;

	#similarbedssection {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		background-color: var(--color-grey-light);
		visibility: hidden;
		transition: all 0.5s ease-out;
		z-index: 3;
	}

	#similarfacilitysection {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		background-color: var(--color-grey-light);
		z-index: 2;
	}
}

.similar-units-tabs {
	margin-bottom: 20px;
	border-bottom: 1px solid var(--color-secondary);

	.tab {

		display: inline-block;
		padding: 3px 15px;
		background-color: var(--color-secondary);
		color: var(--color-white);
		cursor: pointer;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;

		@media(max-width: 560px) {
			font-size: var(--font-size-md);
			padding: 3px 10px;
		}

		&.active {
			background-color: var(--color-primary);
		}
	}

	.desktop {
		display: none;

		@media(min-width: 561px) {
			display: block;
		}
	}

	.mobile {
		display: none;


		@media(max-width: 560px) {
			display: block;
		}
	}
}