.gastronomie-page-layout-v1 {
	margin-top: 110px;

	.inner-banner {
		background-image: url(RESOURCE/img/gastronomie-banner.jpg);
	}

	.restaurant-redensee {
		width: 100px;
	}
}