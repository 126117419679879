.aktivitaten-page-layout-v1 {
	.inner-banner {
		background-image: url(RESOURCE/img/aktivitaten-banner.jpg);
	}

	.aktivitaten-bl {
		width: 100%;
		height: 4660px;
		border: 0;

		@media (min-width: 1920px) {
			height: 5200px;
		}

		@media (max-width: 1500px) {
			height: 4500px;
		}

		@media (max-width: 1199px) {
			height: 3960px;
		}

		@media (max-width: 992px) {
			height: 3890px;
		}

		@media (max-width: 767px) {
			height: 7900px
		}

		@media (max-width: 600px) {
			height: 6880px
		}

		@media (max-width: 500px) {
			height: 6300px
		}

		@media (max-width: 375px) {
			height: 5750px
		}
	}
}