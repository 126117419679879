.static-view {
	&.Lage-page-layout-v1 {
		.lage-page {
			position: relative;

			.btn {
				position: absolute;
				right: 0;
			}
		}
	}

	&.specials-page-layout-v1 {
		iframe {
			width: 100%;
			height: 3250px;
			border: 0;

			@media (min-width: 1921px) {
				height: 3800px;
			}

			@media (max-width: 992px) {
				height: 3360px;
			}

			@media (max-width: 767px) {
				height: 6590px
			}

			@media (max-width: 600px) {
				height: 5560px
			}

			@media (max-width: 500px) {
				height: 5380px
			}

			@media (max-width: 375px) {
				height: 5080px
			}
		}
	}
}